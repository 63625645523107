import Request from '@hotmart/request';
import config from '../../env.config';

const options = { headers: { application: 'Mkt' } };

export const Constants = {
  RESULTS: 'content_search.results',
  RESULTS_VERSION: 'search.results_version',
  RESULTS_INTERNAL: 'search.results_internal',
  RESULTS_BY_CATEGORIES: 'search.results_by_categories',
  RESULTS_TOPIFICATION: 'search.results_topification',
  DISPLAYS_HIGHLIGHTED: 'displays.highlighted',
  CATEGORIES: 'categories.list',
  CATEGORIES_INTERNAL: 'categories.list_internal',
  USER: 'user.vulcano',
  AUTHCHECK: 'auth.check',
  CHECKTOKEN: 'auth.check_token',
  LOGOUT: 'auth.logout',
  PRODUCT_IDS: 'products.list',
  SEARCH_GROUPBY_CATEGORIES: 'search.groupby_categories',
  SEARCH_GROUPBY_CATEGORIES_INTERNAL: 'search.groupby_categories_internal',
  BEST_SELLERS_RANDOM: 'search.random_best_sellers',
  PRODUCTS_RECOMMENDATION: 'search.products_recommendation',
  PRODUCTS_RECOMMENDATION_INTERNAL: 'search.products_recommendation_internal',
  TOP_SALES: 'search.top_sales',
  RESULTS_FAVORITES: 'search.results_favorites',
  RANDOM_CAMPAIGN: 'search.random_campaign',
  INFO_CAMPAIGN: 'info.campaign',
  STATUS_CAMPAIGN: 'status.campaign',
  BANNER_CAMPAIGN: 'banner.campaign',
  PRICE: 'checkout.price',
  FEATURES_SWITCH: 'display.feature_switch',
  FEATURES_SWITCH_INTERNAL: 'display.feature_switch_internal',
  GET_BY_ID: 'search.get_by_id',
  GET_FILTERS_COUNT: 'content_search.filter_count'
};

Request.register(config.envConfig.ENDPOINT_DISPLAY_CDN, {
  get: {
    [Constants.FEATURES_SWITCH]: '/v1/config/public'
  }
});

Request.register(config.envConfig.ENDPOINT_DISPLAY_INTERNAL, {
  get: {
    [Constants.FEATURES_SWITCH_INTERNAL]: '/back/api/display/v1/config/public'
  }
});

Request.register(config.envConfig.CONTENT_ENDPOINT_INTERNAL, {
  get: {
    [Constants.RESULTS_INTERNAL]: '/v1/product/search',
    [Constants.PRODUCTS_RECOMMENDATION_INTERNAL]: '/v1/product/recommendation'
  }
});

Request.register(config.envConfig.ENDPOINT_INTERNAL, {
  get: {
    [Constants.CATEGORIES_INTERNAL]: '/categories',
    [Constants.SEARCH_GROUPBY_CATEGORIES_INTERNAL]:
      '/api/v2/product/search/quantity/groupby/category'
  }
});

Request.register(config.envConfig.ENDPOINT, {
  get: {
    [Constants.RESULTS_VERSION]: '/api/v3/product/search',
    [Constants.RESULTS_TOPIFICATION]: '/api/display-search/v2/product/search',
    [Constants.DISPLAYS_HIGHLIGHTED]: '/displays',
    [Constants.CATEGORIES]: '/categories',
    [Constants.PRODUCT_IDS]: '/api/v1/product/ids',
    [Constants.GET_BY_ID]: 'api/v1/product/ids?productsIds=:ids',
    [Constants.RESULTS_BY_CATEGORIES]:
      '/api/v1/product/search/groupby/category',
    [Constants.SEARCH_GROUPBY_CATEGORIES]:
      '/api/v2/product/search/quantity/groupby/category',
    [Constants.TOP_SALES]: '/api/v1/product/topSales',
    [Constants.RESULTS_FAVORITES]: '/api/v2/product/msearch',
    [Constants.RANDOM_CAMPAIGN]: '/api/v1/product/search/random'
  }
});

Request.register(config.envConfig.CONTENT_ENDPOINT, {
  get: {
    [Constants.RESULTS]: '/v1/product/search',
    [Constants.GET_FILTERS_COUNT]: '/v1/product/facets',
    [Constants.PRODUCTS_RECOMMENDATION]: '/v1/product/recommendation'
  }
});

Request.register(config.envConfig.CAMPAIGN, {
  get: {
    [Constants.INFO_CAMPAIGN]: '/campaign/configs/:slug/config.json',
    [Constants.BANNER_CAMPAIGN]: '/campaign/state/running/configV2.json'
  }
});

Request.register(config.envConfig.OAUTH, {
  get: {
    [Constants.AUTHCHECK]: '/user/me'
  }
});

Request.register(config.envConfig.CHECK_TOKEN, {
  post: {
    [Constants.CHECKTOKEN]: '/check_token'
  }
});

Request.register(config.envConfig.LOGOUT, {
  get: {
    [Constants.LOGOUT]: '/oauth/logout'
  }
});

Request.register(
  config.envConfig.VLC,
  {
    get: {
      [Constants.USER]: '/userprofile/rest/v1/user/profile'
    }
  },
  options
);

Request.register(config.envConfig.CHECKOUT, {
  post: {
    [Constants.PRICE]: '/hotmart-checkout/api/v1/checkoutPrice/offerPrices'
  }
});
