import Cookie from 'js-cookie';

export function substringText(text: string, length = 0) {
  const stringText = `${text}`;
  return `${stringText.substring(0, length).trim()}${
    stringText.length > length ? '...' : ''
  }`;
}

export function sanitize(data: string) {
  return data.replace(/[[/\]{}()*+?.,\\^$|#\s]/g, ' ');
}

export function getRemoteAddr(ip: string) {
  let ipAddress = ip;

  if (ipAddress?.match(/,/)) {
    [ipAddress] = ipAddress.split(',');
  }

  if (ip?.match(/ff/)) {
    ipAddress = '';
  }

  return ipAddress;
}

export function isUpperCase(text: string) {
  return /^[A-Z0-9À-Ö!?Ú–@\-,#$&() ]*$/.test(text);
}

export enum BannerVariants {
  DEFAULT = 'default',
  TAG = 'tag',
  CREATOR_TAG = 'creator-tag'
}

export function getParamsUser() {
  const ga = Cookie.get('_ga') || null;
  const hotid = Cookie.get('hotid') || null;

  return {
    ...(ga ? { ga } : {}),
    ...(!ga && hotid ? { hotid } : {})
  };
}
