import { IncomingHttpHeaders } from 'http';

export const normalizeLanguageForApi = (language : Language) => {
  if (language.match(/pt/)) {
    return 'pt';
  }

  return language;
};

export function getLanguagePrefix(language : Language) {
  try {
    return (`${language}`.split('-')[0] || 'en') as Language;
  } catch (err) {
    return 'en';
  }
}

export function verifyLanguage(language?: Language) {
  if (!language) return 'en';
  const lowerLanguage = language.toString().toLowerCase() as Language;


  if (lowerLanguage.match(/^pt/)) {
    return 'pt-br';
  }

  if (lowerLanguage.match(/^en/)) {
    return 'en';
  }

  if (lowerLanguage.match(/^es/)) {
    return 'es';
  }

  if (lowerLanguage.match(/^fr/)) {
    return 'fr';
  }

  if (lowerLanguage.match(/^ar/)) {
    return 'ar';
  }

  return 'en';
}

export function verifyLanguageExternal(language : Language) {
  if (!language) {
    return undefined;
  }

  if (language.match(/^pt/)) {
    return 'pt-br';
  }

  return language;
}

export function capitalizeLocale(language : Language) {
  if (language.match(/^pt/)) {
    return 'PT_BR';
  }

  return language ? language?.toLocaleUpperCase() : language;
}

export function getLanguageFromUrlOrCookie(cookies?: Record<string, string>, query?: Record<string, string | string[] | undefined>, locale?: Language, header?: IncomingHttpHeaders) {
  if (header && header['accept-language']) {
    return verifyLanguage(header['accept-language'] as Language);
  }

  if (query?.lng) {
    return verifyLanguage(query.lng as Language);
  }

  if (cookies?.hmLangCookie) {
    return verifyLanguage(cookies.hmLangCookie as Language);
  }

  return locale;
}

export function isPortuguese(language :  Language) {
  return verifyLanguage(language).match(/^pt/);
}
