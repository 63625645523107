import { verifyLanguage, verifyLanguageExternal } from 'utils/language';
import {
  mountMarketplaceCampaignPage,
  generateBlackNovemberCampaignByLanguage
} from 'utils/url';
import { FIRE_2023_DATA } from '@core/data/constants/fire-2023-campaign-data';
import {
  campaign as CAMPAIGN,
  CampaignType,
  seasonInfo,
  sectionCampaign,
  statusCampaign
} from '../constants';

export const convertDateTime = (dateTime, string, time = false) => {
  return Object.keys(dateTime)
    .map(item =>
      !time ? `${dateTime[item]}` : `${dateTime[item]?.toString()?.slice(0, 2)}`
    )
    .join(string);
};

export function isSetupBannerActive(
  currentLanguage: Language,
  setupLanguage: Language
) {
  return (
    !!currentLanguage &&
    verifyLanguage(currentLanguage) === verifyLanguageExternal(setupLanguage)
  );
}

export function isCreatorBlackNovember(
  isBlackNovemberActive: boolean,
  campaigns: []
) {
  return (
    isBlackNovemberActive &&
    campaigns?.length > 0 &&
    campaigns?.some(campaign => campaign === seasonInfo.COUPON_TYPE)
  );
}

export const validateDateTime = (data?: DateTime) => {
  if (!data) return null;
  const startDate = `${data?.date?.year}/${data.date?.month}/${data?.date?.day}`;
  const endDate = `${data?.time?.hour}:${data?.time?.minute}:${data?.time?.second}`;
  const finalDate = `${startDate} ${endDate}`;

  return finalDate || null;
};

export const languageCountries = (country: string) => {
  switch (country) {
    case 'brazil':
      return 'pt';
    case 'usa':
      return 'en';
    case 'france':
      return 'fr';
    case 'netherlands':
      return 'nl';
    case 'colombia':
    case 'spain':
    case 'mexico':
    case 'latam':
      return 'es';
    default:
      return 'pt';
  }
};

export const campaignSection = (campaign: string, sections) => {
  switch (campaign) {
    case sectionCampaign.HOME:
      return {
        backgroundImage: sections[sectionCampaign.BACKGROUND_IMAGE]?.urlFinal,
        backgroundImage_MOBILE:
          sections[sectionCampaign.BACKGROUND_IMAGE_MOBILE]?.urlFinal,
        buttonLabel: sections[sectionCampaign.CTA]?.value,
        buttonLabel_MOBILE: sections[sectionCampaign.CTA_MOBILE]?.value,
        buttonColor: sections[sectionCampaign.CTA]?.ctaBtnColor,
        buttonColor_MOBILE: sections[sectionCampaign.CTA_MOBILE]?.ctaBtnColor,
        description: sections[sectionCampaign.TEXT]?.value,
        description_MOBILE: sections[sectionCampaign.TEXT_MOBILE]?.value,
        main: sections[sectionCampaign.MAIN_IMAGE]?.urlFinal,
        main_MOBILE: sections[sectionCampaign.MAIN_IMAGE_MOBILE]?.urlFinal,
        logo: sections[sectionCampaign.LOGO_IMAGE]?.urlFinal,
        logo_MOBILE: sections[sectionCampaign.LOGO_IMAGE_MOBILE]?.urlFinal,
        ctaUrl: sections[sectionCampaign.CTA_URL]?.value,
        ctaUrl_MOBILE: sections[sectionCampaign.CTA_URL_MOBILE]?.value
      };
    case sectionCampaign.MAIN_PAGE:
      return {
        BANNER: sections[sectionCampaign.BACKGROUND_IMAGE]?.urlFinal,
        BANNER_MOBILE:
          sections[sectionCampaign.BACKGROUND_IMAGE_MOBILE]?.urlFinal,
        LOGO_SEARCH: sections[sectionCampaign.LOGO_IMAGE]?.urlFinal,
        LOGO_SEARCH_MOBILE:
          sections[sectionCampaign.LOGO_IMAGE_MOBILE]?.urlFinal,
        TEXT_SEARCH: sections[sectionCampaign.TEXT]?.value,
        TEXT_SEARCH_MOBILE: sections[sectionCampaign.TEXT_MOBILE]?.value,
        TEXT_PLACEHOLDER: sections[sectionCampaign.SEARCH_TEXT]?.value,
        TEXT_PLACEHOLDER_MOBILE:
          sections[sectionCampaign.SEARCH_TEXT_MOBILE]?.value
      };
    case sectionCampaign.PRODUCT_CARD:
      return {
        PRODUCT_CARD_IMAGE:
          sections[sectionCampaign.BACKGROUND_IMAGE]?.urlFinal,
        PRODUCT_CARD_IMAGE_MOBILE:
          sections[sectionCampaign.BACKGROUND_IMAGE_MOBILE]?.urlFinal,
        PRODUCT_CARD_LOGO: sections[sectionCampaign.LOGO_IMAGE]?.urlFinal,
        PRODUCT_CARD_LOGO_MOBILE:
          sections[sectionCampaign.LOGO_IMAGE_MOBILE]?.urlFinal,
        PRODUCT_CARD_MAIN: sections[sectionCampaign.MAIN_IMAGE]?.urlFinal,
        PRODUCT_CARD_MAIN_MOBILE:
          sections[sectionCampaign.MAIN_IMAGE_MOBILE]?.urlFinal
      };
    case sectionCampaign.RESULT_PAGE:
    case sectionCampaign.CATEGORY_PAGE:
      return {
        BANNER_HOME_BUTTON_PT: sections[sectionCampaign.CTA]?.value,
        BANNER_HOME_BUTTON_PT_MOBILE:
          sections[sectionCampaign.CTA_MOBILE]?.value,
        BANNER_HOME_BACKGROUND_COLOR:
          sections[sectionCampaign.CTA]?.ctaBtnColor,
        BANNER_HOME_BACKGROUND_COLOR_MOBILE:
          sections[sectionCampaign.CTA_MOBILE]?.ctaBtnColor,
        BANNER_RESULTS_PAGE_COLOR: sections[sectionCampaign.CTA]?.ctaBtnColor,
        BANNER_RESULTS_PAGE_COLOR_MOBILE:
          sections[sectionCampaign.CTA_MOBILE]?.ctaBtnColor,
        BANNER_RESULTS_PAGE_SUBTITLE_PT: sections[sectionCampaign.TEXT]?.value,
        BANNER_RESULTS_PAGE_SUBTITLE_PT_MOBILE:
          sections[sectionCampaign.TEXT_MOBILE]?.value,
        BANNER_RESULTS_PAGE_IMAGE:
          sections[sectionCampaign.BACKGROUND_IMAGE]?.urlFinal,
        BANNER_RESULTS_PAGE_IMAGE_MOBILE:
          sections[sectionCampaign.BACKGROUND_IMAGE_MOBILE]?.urlFinal,
        BANNER_RESULTS_PAGE_LOGO:
          sections[sectionCampaign.LOGO_IMAGE]?.urlFinal,
        BANNER_RESULTS_PAGE_LOGO_MOBILE:
          sections[sectionCampaign.LOGO_IMAGE_MOBILE]?.urlFinal,
        BANNER_RESULTS_PAGE_MAIN:
          sections[sectionCampaign.MAIN_IMAGE]?.urlFinal,
        BANNER_RESULTS_PAGE_MAIN_MOBILE:
          sections[sectionCampaign.MAIN_IMAGE_MOBILE]?.urlFinal,
        COMBO: sections[sectionCampaign.COMBO]?.value,
        ctaUrl: sections[sectionCampaign.CTA_URL]?.value,
        ctaUrl_MOBILE: sections[sectionCampaign.CTA_URL_MOBILE]?.value
      };
    case sectionCampaign.MENU:
      return sections;
    default:
      return null;
  }
};

export const extractDetailsCampaign = (
  campaign?: object,
  name?: string,
  section?: boolean,
  currentLanguage?: Language,
  campaignLanguage?: Language,
  validLanguage?: boolean
) => {
  if (!currentLanguage) return {};

  const getSectionCampaign = () => {
    let isLanguage = false;

    if (campaign) {
      return Object.keys(campaign)
        .map(item => {
          if (validLanguage) {
            isLanguage = false;
            if (
              campaign[item]?.country &&
              isSetupBannerActive(
                currentLanguage,
                languageCountries(campaign[item]?.country)
              )
            ) {
              isLanguage = true;
            } else if (
              campaignLanguage &&
              isSetupBannerActive(currentLanguage, campaignLanguage)
            ) {
              isLanguage = true;
            }
          } else {
            isLanguage = true;
          }

          if (isLanguage) {
            if (!section) {
              return campaign[item].screen;
            }
            if (campaign[item].name === section) {
              return campaign[item].screen;
            }
          }

          return undefined;
        })
        .indexOf(name);
    }
    return -1;
  };

  const sectionData = getSectionCampaign();
  const mobile = 'MOBILE';
  const sections = {};
  let campaignObj: any = {};
  let url = '';

  const mountDeviceType = (type: string) => (type === mobile ? '_MOBILE' : '');

  if (sectionData > -1 && campaign) {
    url = campaign[sectionData]?.url
      ? `${mountMarketplaceCampaignPage(
        currentLanguage,
        campaign[sectionData]?.url
      )}`
      : '';
    const components = campaign[sectionData]?.components;

    if (components) {
      Object.keys(components).forEach(item => {
        sections[
          `${components[item].name}${mountDeviceType(
            components[item].deviceType
          )}`
        ] = components[item].attributes;
      });
    }
  }

  if (sections && Object.keys(sections).length > 0) {
    campaignObj = campaignSection(name, sections);
    campaignObj = {
      ...campaignObj,
      ctaUrl: campaignObj?.ctaUrl || url,
      ctaUrl_MOBILE: campaignObj?.ctaUrl_MOBILE || url
    };
  }

  return campaignObj;
};

export function isCampaignActive(data: {
  START_DATE: string | Date;
  END_DATE: string | Date;
}) {
  const startDate = new Date(data.START_DATE);
  const endDate = new Date(data.END_DATE);
  const today = new Date();

  return startDate < today && today < endDate;
}

export const getDataCampaign = () => {
  const setupCampaign = isCampaignActive(FIRE_2023_DATA.date)
    ? FIRE_2023_DATA
    : { sections: [] };

  return setupCampaign;
};

const setBlackNovemberInfo = ({
  setupCampaign,
  startDate,
  endDate,
  campaign,
  locale
}) => {
  const startDateTime: DateTime = {
    date: {
      year: startDate.getFullYear().toString(),
      month: startDate.getMonth().toString(),
      day: startDate.getUTCDate().toString()
    },
    time: {
      hour: startDate.getHours().toString(),
      minute: startDate.getMinutes().toString(),
      second: startDate.getSeconds().toString()
    }
  };

  const endDateTime: DateTime = {
    date: {
      year: endDate.getFullYear().toString(),
      month: endDate.getMonth().toString(),
      day: endDate.getUTCDate().toString()
    },
    time: {
      hour: endDate.getHours().toString(),
      minute: endDate.getMinutes().toString(),
      second: endDate.getSeconds().toString()
    }
  };

  setupCampaign.sections = seasonInfo.sections;
  setupCampaign.TYPE = seasonInfo.TYPE;
  setupCampaign.PREFIX = seasonInfo.PREFIX;
  setupCampaign.name = seasonInfo.NAME;
  setupCampaign.GROUP_KEY = [seasonInfo.GROUP_KEY];
  setupCampaign.START_DATE = validateDateTime(startDateTime);
  setupCampaign.END_DATE = validateDateTime(endDateTime);
  setupCampaign.LANGUAGE = languageCountries(locale);
  setupCampaign.RESULTS_PAGE = `${generateBlackNovemberCampaignByLanguage(
    locale,
    seasonInfo.RESULTS_PAGE
  )}`;
  setupCampaign.status = statusCampaign.RUNNING;
  setupCampaign.url = campaign;
  setupCampaign.style = 'static/styles/black-november.css';

  return setupCampaign;
};

export function isCampaignLanguage(
  isActive: boolean,
  currentLanguage?: Language
) {
  const currentlng = verifyLanguage(currentLanguage);
  let lgnShowActive = true;

  if (currentLanguage) {
    if (
      currentlng === 'pt-br' ||
      currentlng === 'es' ||
      currentlng === 'en' ||
      currentlng === 'fr'
    ) {
      lgnShowActive = true;
    } else {
      lgnShowActive = false;
    }
  }

  return isActive && lgnShowActive;
}

export function isBlackNovember() {
  return isCampaignActive(seasonInfo);
}

export function isBlackNovemberRoute(route: string) {
  return route?.includes('black-november');
}

export const getCampaignBySlug = async (
  campaign: CampaignType,
  locale: Language,
  route?: string,
  forceActivation?: string
) => {
  let setupCampaign: any = {};

  if (
    (isBlackNovember() || forceActivation === seasonInfo.GROUP_KEY) &&
    isBlackNovemberRoute(route)
  ) {
    const startDate = new Date(seasonInfo.START_DATE);
    const endDate = new Date(seasonInfo.END_DATE);

    setupCampaign = setBlackNovemberInfo({
      setupCampaign,
      startDate,
      endDate,
      campaign,
      locale
    });

    setupCampaign = {
      ...seasonInfo,
      ...setupCampaign
    };
  }

  return setupCampaign as CampaignType;
};

export function counterBlackNovember() {
  const today = new Date();
  const blackNovemberDate = new Date(seasonInfo.END_DATE);

  const diff = Math.abs(today.getTime() - blackNovemberDate.getTime());
  return Math.ceil(diff / (1000 * 3600 * 24));
}

export function hasCouponBlackNovember(coupons: any) {
  return coupons?.find(
    coupon => coupon?.couponType === seasonInfo.COUPON_TYPE.toLocaleLowerCase()
  );
}

export const validateCampaignDate = (campaign?: CampaignType) => {
  const today = new Date();
  let valid = false;

  try {
    if (campaign?.START_DATE && campaign?.END_DATE) {
      const startDate = new Date(campaign?.START_DATE);
      const endDate = new Date(campaign?.END_DATE);

      if (startDate < today && today < endDate) {
        valid = true;
      }
    }
  } catch (err) {
    console.error(err);
  }

  return valid;
};

export function isCyberWeek(currentLanguage?: Language) {
  const isActive = isCampaignActive(CAMPAIGN['cyber-week']);
  const lgnShowActive = currentLanguage
    ? isCampaignLanguage(isActive, currentLanguage)
    : true;

  return isActive && lgnShowActive;
}

export function convertUTCDateToLocalDate(date: string) {
  const currentDate = new Date(date);
  const formatedDate = new Date(
    currentDate.getTime() - currentDate.getTimezoneOffset() * 60 * 1000
  );

  return formatedDate;
}

export function isSeasonActive(currentLanguage: Language, seasonInfoObj?: any) {
  const campaignLanguage = seasonInfoObj?.LANGUAGE || 'pt';
  const isCampLanguage = !seasonInfoObj?.defaultLanguage
    ? currentLanguage && verifyLanguage(currentLanguage) !== campaignLanguage
    : false;

  if (isCampLanguage) {
    return false;
  }

  const seasonInfoCurrent = seasonInfoObj || seasonInfo;
  const startDate = convertUTCDateToLocalDate(seasonInfoCurrent.START_DATE);
  const endDate = convertUTCDateToLocalDate(seasonInfoCurrent.END_DATE);
  const today = new Date();

  return startDate < today && today < endDate;
}
