/* eslint-disable no-use-before-define */

import React, { useState } from 'react';
import cookie from 'react-cookies';

/* First we will make a new context */
export const StoreContext = React.createContext();
const STORAGE = 'mkt_storage';

/* Then create a provider Component */
function StoreProvider(props) {

  const information = {
    user: {
      name: '',
      authorities: []
    },
    tokenMkt: null,
    actions: {
      clean: () => clean(),
      setUser: (user) => setUser(user),
      setToken: (token) => setToken(token),
      saveStorage: (user, token) => saveStorage(user, token),
      incrementUser: (user) => incrementUser(user),
      isLogged: () => isLogged,
      haveRights: (authoritie) => haveRights(authoritie)
    },
    changeState: (property, value) => {
      setInfo( prevState => {
        return {
          ...prevState,
          [property]: value
        };
      });
    }
  };

  const [info, setInfo] = useState(information);

  const setUser = user => {
    info.changeState('user', user);
  };

  const incrementUser = async (user) => {
    const userPromise = await user;
    const userRedefined = { ...info.user, ...userPromise };

    info.changeState('user', userRedefined);
  };

  const clean = () => {
    info.changeState('user', { name: '' });
    info.changeState('tokenMkt', '');
    localStorage.removeItem(STORAGE);
    localStorage.removeItem('tokenMkt');
    localStorage.removeItem('token');
    cookie.remove('hmVlcIntegration');
  };

  const isLogged = () => !!info.tokenMkt;

  const saveStorage = (user, token) => {
    localStorage.setItem(STORAGE, JSON.stringify({ ...user, tokenMkt: token }));
  };

  const setToken = (token) => {
    info.changeState('tokenMkt', token);
    localStorage.setItem('tokenMkt', token);
  };

  const haveRights = (authoritie) => {
    const rights = this.state.user.authorities.find(rule => rule === authoritie);
    return rights && rights.length >= 1;
  };

  return (
    <StoreContext.Provider value={info}>
      {props.children}
    </StoreContext.Provider>
  );
}

/* then make a consumer which will surface it */
const StoreConsumer = StoreProvider.Consumer;

export default StoreProvider;
export { StoreConsumer };
