const envConfig = {
  production: {
    APP: {
      PORT: '3002',
      HOST: 'hotmart.com',
      URL: 'https://hotmart.com'
    },
    APP_PRODUCTPAGE: {
      URL: 'https://hotmart.com'
    },
    PATH_UNDER_HOTMART_COM: {
      marketplace: 'marketplace',
      search: 'search',
      result: 's',
      season: 'black-november',
      campaign: 'cyber-week',
      category: 'category/:slug'
    },
    ANALYTICS: 'https://launcher.hotmart.com',
    AUTH_HOTMART: 'https://hotmart.com/auth',
    INTEGRATION_COOKIE_PATH: 'hotmart.com',
    WWW_COOKIE_DOMAIN: 'hotmart.com',
    PRODUCT_PAGE: 'https://hotmart.com/product',
    CATEGORY_PAGE: 'https://hotmart.com/category',
    CHECKOUT: 'https://api-hotmart-async-checkout.hotmart.com',
    PRICES: 'https://api-display-gateway.dsp.hotmart.com',
    CONTENT_ENDPOINT: 'https://api-content-search.dsp.hotmart.com',
    CONTENT_ENDPOINT_INTERNAL: 'https://api-content-search.display.aws.hotmart',
    ENDPOINT: 'https://api-display-search.hotmart.com',
    ENDPOINT_INTERNAL: 'https://api-display-search.display.aws.hotmart',
    HUB_ENDPOINT: 'https://api-hub.hotmart.com',
    ENDPOINT_DISPLAY_BACK: 'https://api-display.hotmart.com/back',
    ENDPOINT_DISPLAY_CDN: 'https://hotmart.com/back/api/display',
    ENDPOINT_DISPLAY_INTERNAL: 'https://api-display.display.aws.hotmart',
    LOGIN: 'https://app-vlc.hotmart.com',
    PLATFORM: 'https://app-vlc.hotmart.com',
    VLC: 'https://api-vlc.hotmart.com',
    OAUTH: 'https://api-sec-vlc.hotmart.com:443/security/rest/v1',
    CHECK_TOKEN: 'https://api-sec-vlc.hotmart.com:443/security/oauth',
    LOGOUT: 'https://api-sec-vlc.hotmart.com/security',
    HUB: 'https://purchase.hotmart.com/users',
    HUB_PRODUCTS: 'https://purchase.hotmart.com/purchase',
    RESULTS_PAGE: 'https://hotmart.com/s',
    SSO_ENDPOINT: 'https://sso.hotmart.com/signup',
    SENTRY_DSN:
      'https://ac68efbe3c9c451b9b7ba08785ec16f9@o49094.ingest.us.sentry.io/5193783',
    CAMPAIGN: 'https://dsp-cdn.hotmart.com',
    SPARKLE: 'https://api.sparkleapp.com.br/rest',
    RESULTS: {
      QTY_PER_PAGE: 24,
      MIN_SEARCH_CHARACTERS: 3
    },
    PRODUCTS: {
      QTY_WELL_REVIEWED: 8,
      QTY_NEWBIES: 8,
      QTY_PRODUCTS_USER_PAGE: 12,
      QTY_SEARCH_OTHERS_CATEGORY: 8
    },
    FEATURE_TOGGLE: {
      SURVICATE: true
    }
  },
  staging: {
    APP: {
      PORT: '3002',
      HOST: 'buildstaging.com',
      URL: 'https://buildstaging.com'
    },
    APP_PRODUCTPAGE: {
      URL: 'https://buildstaging.com'
    },
    PATH_UNDER_HOTMART_COM: {
      marketplace: 'marketplace',
      search: 'search',
      result: 's',
      season: 'black-november',
      campaign: 'cyber-week',
      category: 'category/:slug'
    },
    ANALYTICS: 'https://api-launcher.buildstaging.com',
    AUTH_HOTMART: 'https://buildstaging.com/auth',
    INTEGRATION_COOKIE_PATH: 'buildstaging.com',
    WWW_COOKIE_DOMAIN: 'buildstaging.com',
    PRODUCT_PAGE: 'https://buildstaging.com/product',
    CAMPAIGN: 'https://dsp-cdn.buildstaging.com',
    CATEGORY_PAGE: 'https://buildstaging.com/category',
    CHECKOUT: 'https://api-hotmart-checkout.buildstaging.com',
    CONTENT_ENDPOINT: 'https://api-content-search.buildstaging.com',
    CONTENT_ENDPOINT_INTERNAL: 'https://api-content-search.buildstaging.com',
    ENDPOINT: 'https://api-display-search.buildstaging.com',
    ENDPOINT_INTERNAL: 'https://api-display-search.buildstaging.aws.hotmart',
    HUB_ENDPOINT: 'https://api-hub.buildstaging.com',
    ENDPOINT_DISPLAY_BACK: 'https://api-display.buildstaging.com/back',
    ENDPOINT_DISPLAY_CDN: 'https://buildstaging.com/back/api/display',
    ENDPOINT_DISPLAY_INTERNAL: 'https://api-display.buildstaging.aws.hotmart',
    LOGIN: 'https://app-vulcano.buildstaging.com',
    PLATFORM: 'https://app-vulcano.buildstaging.com',
    VLC: 'https://api-marketplace.buildstaging.com',
    OAUTH: 'https://api-security.buildstaging.com:443/security/rest/v1',
    CHECK_TOKEN: 'https://api-security.buildstaging.com/security/oauth',
    HUB: 'https://app-hub.buildstaging.com/users',
    HUB_PRODUCTS: 'https://app-hub.buildstaging.com/purchase',
    SENTRY_DSN:
      'https://ac68efbe3c9c451b9b7ba08785ec16f9@o49094.ingest.us.sentry.io/5193783',
    LOGOUT: 'https://api-security.buildstaging.com/security',
    PRICES: 'https://api-display-gateway.buildstaging.com',
    RESULTS_PAGE: 'https://buildstaging.com/s',
    SSO_ENDPOINT: 'https://sso.buildstaging.com/signup',
    SPARKLE: 'https://api-sparkle.buildstaging.com/rest',
    RESULTS: {
      QTY_PER_PAGE: 24,
      MIN_SEARCH_CHARACTERS: 3
    },
    PRODUCTS: {
      QTY_WELL_REVIEWED: 8,
      QTY_NEWBIES: 8,
      QTY_PRODUCTS_USER_PAGE: 12,
      QTY_SEARCH_OTHERS_CATEGORY: 8
    },
    FEATURE_TOGGLE: {
      SURVICATE: true
    }
  },
  development: {
    APP: {
      PORT: '3002',
      HOST: 'localhost:3002',
      URL: 'http://localhost:3002'
    },
    APP_PRODUCTPAGE: {
      URL: 'http://localhost:3001'
    },
    PATH_UNDER_HOTMART_COM: {
      marketplace: 'marketplace',
      search: 'search',
      result: 's',
      season: 'black-november',
      campaign: 'cyber-week',
      category: 'category/:slug'
    },
    ANALYTICS: 'https://api-launcher.buildstaging.com',
    AUTH_HOTMART: 'http://local.app-hotmart-auth.buildstaging.com:3001/auth',
    INTEGRATION_COOKIE_PATH: 'localhost',
    WWW_COOKIE_DOMAIN: 'localhost',
    PRODUCT_PAGE: 'https://buildstaging.com/product',
    CATEGORY_PAGE: 'http://localhost:3002/category',
    CAMPAIGN: 'https://dsp-cdn.buildstaging.com',
    CHECKOUT: 'https://api-hotmart-checkout.buildstaging.com',
    CHECKOUT_LOAD: 'https://api-checkout-load.buildstaging.com',
    CONTENT_ENDPOINT: 'https://api-content-search.buildstaging.com',
    CONTENT_ENDPOINT_INTERNAL: 'https://api-content-search.buildstaging.com',
    ENDPOINT: 'https://api-display-search.buildstaging.com',
    ENDPOINT_INTERNAL: 'https://api-display-search.buildstaging.aws.hotmart',
    HUB_ENDPOINT: 'https://api-hub.buildstaging.com',
    ENDPOINT_DISPLAY_BACK: 'https://api-display.buildstaging.com/back',
    ENDPOINT_DISPLAY_CDN: 'https://buildstaging.com/back/api/display',
    ENDPOINT_DISPLAY_INTERNAL: 'https://api-display.buildstaging.aws.hotmart',
    LOGIN: 'https://app-vulcano.buildstaging.com',
    PLATFORM: 'https://app-vulcano.buildstaging.com',
    VLC: 'https://api-marketplace.buildstaging.com',
    OAUTH: 'https://api-security.buildstaging.com:443/security/rest/v1',
    CHECK_TOKEN: 'https://api-security.buildstaging.com/security/oauth',
    HUB: 'https://app-hub.buildstaging.com/users',
    HUB_PRODUCTS: 'https://app-hub.buildstaging.com/purchase',
    SENTRY_DSN:
      'https://ac68efbe3c9c451b9b7ba08785ec16f9@o49094.ingest.us.sentry.io/5193783',
    LOGOUT: 'https://api-security.buildstaging.com/security',
    PRICES: 'https://api-display-gateway.buildstaging.com',
    RESULTS_PAGE: 'http://localhost:3002/s',
    SSO_ENDPOINT: 'https://sso.buildstaging.com/signup',
    SPARKLE: 'https://api-sparkle.buildstaging.com/rest',
    RESULTS: {
      QTY_PER_PAGE: 24,
      MIN_SEARCH_CHARACTERS: 3
    },
    PRODUCTS: {
      QTY_WELL_REVIEWED: 8,
      QTY_NEWBIES: 8,
      QTY_PRODUCTS_USER_PAGE: 12,
      QTY_SEARCH_OTHERS_CATEGORY: 8
    },
    FEATURE_TOGGLE: {
      SURVICATE: true
    }
  },
  analyzer: {
    ASSETS_PREFIX: ''
  },
  test: {
    APP: {
      PORT: '3002',
      HOST: 'localhost:3002'
    },
    PATH_UNDER_HOTMART_COM: {
      marketplace: 'marketplace',
      search: 'search',
      result: 's',
      season: 'black-november',
      campaign: 'cyber-week',
      category: 'category/:slug'
    },
    INTEGRATION_COOKIE_PATH: '.buildstaging.com',
    PRODUCT_PAGE: 'https://buildstaging.com/product',
    CATEGORY_PAGE: 'https://buildstaging.com/category',
    RESULTS_PAGE: 'https://buildstaging.com/s',
    CONTENT_ENDPOINT: 'https://api-content-search.buildstaging.com',
    ENDPOINT: 'https://api-display-search.buildstaging.com',
    HUB_ENDPOINT: 'https://api-hub.buildstaging.com',
    ENDPOINT_DISPLAY_BACK: 'https://api-display.buildstaging.com/back',
    ENDPOINT_DISPLAY_CDN: 'https://buildstaging.com/back/api/display',
    ENDPOINT_DISPLAY_INTERNAL: 'https://api-display.buildstaging.aws.hotmart',
    LOGIN: 'https://app-vulcano.buildstaging.com',
    PLATFORM: 'https://app-vulcano.buildstaging.com',
    VLC: 'https://api-marketplace.buildstaging.com',
    OAUTH: 'https://api-security.buildstaging.com:443/security/rest/v1',
    HUB: 'https://app-hub.buildstaging.com/users',
    HUB_PRODUCTS: 'https://app-hub.buildstaging.com/purchase',
    LOGOUT: 'https://api-security.buildstaging.com/security',
    CAMPAIGN: 'https://dsp-cdn.buildstaging.com',
    SPARKLE: 'https://api.sparkleapp.com.br/rest',
    RESULTS: {
      QTY_PER_PAGE: 24,
      MIN_SEARCH_CHARACTERS: 3
    },
    PRODUCTS: {
      QTY_WELL_REVIEWED: 8,
      QTY_NEWBIES: 8,
      QTY_PRODUCTS_USER_PAGE: 12,
      QTY_SEARCH_OTHERS_CATEGORY: 8
    },
    FEATURE_TOGGLE: {
      SURVICATE: true
    }
  }
};

const currentEnv = process.env.ENV_CONFIG || 'staging';

module.exports = {
  currentEnv,
  envConfig: envConfig[currentEnv]
};
