import { useEffect } from 'react';
import { SWRConfig } from 'swr';
import { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import NextNProgress from 'nextjs-progressbar';
import StoreProvider from '../src/components/core/Provider/Store';
import MarketplaceProvider from '../src/components/core/Provider/MarketplaceProvider';
import { HotmartRum } from '@hotmart-org-ca/hot-observability-js/rum/react';
import envConfig from 'env-config';

import '../src/app-cosmos';
import '../src/global/styles/main.scss';

function MarketplaceApp({ Component, pageProps }: AppProps) {
  const props = {
    ...pageProps
  };
  const swrConfig = {
    revalidateOnFocus: false,
    shouldRetryOnError: true,
    errorRetryInterval: 3000,
    errorRetryCount: 3
  };

  useEffect(() => {
    const version = process.env.DRONE_COMMIT_SHA || '';

    if (envConfig.currentEnv !== 'development') {
      HotmartRum.init({
        release: `app-display-marketplace@${version}`,
        dsn: envConfig.envConfig.SENTRY_DSN,
        environment: process.env.ENV_CONFIG
      });
    }
  }, []);

  return (
    <SWRConfig value={swrConfig}>
      <StoreProvider>
        <NextNProgress height={4} color="#f04e23" />
        <MarketplaceProvider query={pageProps}>
          <Component {...props} />
        </MarketplaceProvider>
      </StoreProvider>
    </SWRConfig>
  );
}

export default appWithTranslation(MarketplaceApp);
