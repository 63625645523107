import { TFunction } from 'next-i18next';
import { envConfig } from '../../env.config';
import {
  campaignPageRoute,
  categoryRoute,
  FILTER,
  normalizeCategory,
  productPageRoute,
  UserRoute
} from '../constants';
import { isPortuguese, verifyLanguage } from './language';
import { isBlackNovember, isBlackNovemberRoute } from './campaigns';

export function addParamToURL(url: string, param?: string, value?: string) {
  if (!value || !param) return url;
  return `${url}${url.match(/[?]/g) ? '&' : '?'}${encodeURIComponent(
    param
  )}=${encodeURIComponent(value)}`;
}

export function convertUrlCategories(route: string, params: { q: string }) {
  return `${route}?q=${params.q}`;
}

export function generateURLQueryString(url: string, qsMap?: object) {
  if (!qsMap || typeof qsMap !== 'object') return url;
  return Object.keys(qsMap).reduce(
    (u, key) => addParamToURL(u, key, qsMap[key]),
    url
  );
}

export function scrollToAnchor(hash?: string) {
  if (typeof window !== 'undefined') {
    window.location.hash = `#${hash}`;
  }
}

export function addParamsOriginToUrl(
  params: { sck?: string; src?: string },
  sck: string,
  src: string
) {
  if (sck) {
    params.sck = sck;
  }

  if (src) {
    params.src = src;
  }

  return params;
}

type BuildAlternateMetaURLParams = {
  language?: Language;
  locales?: Language[];
  resolvedUrl?: string;
  isCategory?: boolean;
  category?: string;
  isCampaign?: boolean;
};

export function buildAlternateMetaURL({
  language,
  locales,
  resolvedUrl,
  isCategory,
  category,
  isCampaign
}: BuildAlternateMetaURLParams) {
  let xDefault = '';

  const urls = (
    <>
      {locales &&
        locales.map(languageAvailable => {
          let urlResolvedWithLanguage = '';
          if (isCategory) {
            const originalCategory = categoryRoute[language];
            const actualCategory = categoryRoute[languageAvailable];
            urlResolvedWithLanguage = resolvedUrl
              .replace(originalCategory, actualCategory)
              .replace(
                normalizeCategory[language][category],
                normalizeCategory[languageAvailable][category]
              );
          } else if (isCampaign) {
            const originalCampaign = campaignPageRoute[language];
            const actualCampaign = campaignPageRoute[languageAvailable];
            urlResolvedWithLanguage = resolvedUrl.replace(
              originalCampaign,
              actualCampaign
            );
          } else {
            urlResolvedWithLanguage = resolvedUrl.replace(
              productPageRoute[language],
              productPageRoute[languageAvailable]
            );
          }

          const url = `${envConfig.APP.URL}/${languageAvailable}${urlResolvedWithLanguage}`;

          if (isPortuguese(languageAvailable)) {
            xDefault = url;
          }
          return (
            <link
              rel="alternate"
              href={`${url}`}
              hrefLang={languageAvailable}
              key={languageAvailable}
            />
          );
        })}
      {xDefault && (
        <link rel="alternate" href={xDefault} hrefLang="x-default" />
      )}
    </>
  );

  return urls;
}

export const getQueryParams = (url: string | URL) => {
  const queryParams = {};
  try {
    const builtURL = url instanceof URL ? url : new URL(url);
    const queryStrings = `${builtURL.search || ''}`.substring(1);
    const params = queryStrings.split('&');

    if (params.length > 0) {
      params.forEach(param => {
        const pair = param.split('=');
        if (pair && pair.length === 2) {
          queryParams[pair[0]] = decodeURIComponent(pair[1]);
        }
      });
    }
  } catch (err) {
    console.error(err);
  }
  return queryParams as any;
};

export const validateLanguageFromUrl = (url: string, parameter: string) => {
  return url
    .replace(new RegExp(`[?&]${parameter}=[^&#]*(#.*)?$`), '$1')
    .replace(new RegExp(`([?&])${parameter}=[^&]*&`), '$1');
};

export function replaceThumborURL(urlWithThumbor: string) {
  return `${urlWithThumbor}`.replace(
    'https://api-thumbor.hotmart.com/',
    'https://static-media.hotmart.com/'
  );
}

export function extractDataFromQuery(name: string, query: object) {
  return (
    (query &&
      query[name] &&
      typeof query[name] === 'string' &&
      query[name].split(FILTER.SEPARATOR)) ||
    []
  );
}

export function prepareForProductPage(language?: Language) {
  if (!language) return productPageRoute.en;
  language = language.toString().toLowerCase() as Language;

  return productPageRoute[language];
}

export function prepareForResultsPage(language?: Language) {
  if (!language) return productPageRoute.en;
  language = language.toString().toLowerCase() as Language;

  return productPageRoute[language];
}

export function prepareForUserPage(language?: Language) {
  if (!language) return UserRoute.en;
  language = language.toString().toLowerCase() as Language;

  return UserRoute[language];
}

export function prepareForCampaignPage(language?: Language) {
  if (!language) return UserRoute.en;
  language = language.toString().toLowerCase() as Language;

  return campaignPageRoute[language];
}

export function mountMarketplaceResultsPage(
  language: Language = 'pt-br',
  query?: object
) {
  let mainUrl = `${envConfig.APP.URL}/${verifyLanguage(
    language
  )}/marketplace/${prepareForResultsPage(language)}`;
  if (query) {
    Object.keys(query).forEach(key => {
      if (Array.isArray(query[key])) {
        const joinedQuery = query[key].join('_-_');

        if (joinedQuery) {
          mainUrl = addParamToURL(mainUrl, key, joinedQuery);
        }
      } else {
        mainUrl = addParamToURL(mainUrl, key, query[key]);
      }
    });
  }
  return mainUrl;
}

export function mountMarketplaceUserPage(language: Language, query) {
  if (query?.name && query?.publicId) {
    return `${envConfig.APP.URL}/${verifyLanguage(
      language
    )}/marketplace/${prepareForUserPage(language)}/${query?.name}/${query?.publicId}`;
  }

  return mountMarketplaceResultsPage(language);
}

export function mountMarketplaceCampaignPage(
  language: Language,
  season: string
) {
  return `${envConfig.APP.URL}/${verifyLanguage(
    language
  )}/marketplace/${prepareForCampaignPage(verifyLanguage(language))}/${season}`;
}

export function mountMarketplaceHomePage(language: Language) {
  return `${envConfig.APP.URL}/${verifyLanguage(language)}/marketplace`;
}

export function mountHotmartHomePage(language: Language) {
  return `${envConfig.APP.URL}/${verifyLanguage(language)}`;
}

export function mountMarketplaceProductSalesPage(
  language: Language,
  slug: string,
  producerReferenceCodePath: string
) {
  return `${envConfig.APP_PRODUCTPAGE.URL}/${verifyLanguage(
    language
  )}/marketplace/${prepareForProductPage(
    verifyLanguage(language)
  )}/${slug}${producerReferenceCodePath}`;
}

export function mountMainUrlSalesPage(language: Language) {
  return `${envConfig.APP.URL}/${verifyLanguage(
    language
  )}/marketplace/${prepareForProductPage(verifyLanguage(language))}`;
}

export function mountMainUrlCategory(language: Language) {
  const categoryByRoute = categoryRoute[language];
  return `${envConfig.APP.URL}/${verifyLanguage(
    language
  )}/marketplace/${categoryByRoute}`;
}

export function generateCategoryByLanguage(
  language?: Language,
  category?: string,
  t?: TFunction
) {
  if (!language) return '';

  language = language.toString().toLowerCase() as Language;

  const categoryByRoute = categoryRoute[language];
  const normalizeCategoryWithLanguage = normalizeCategory[language][category];

  if (!normalizeCategoryWithLanguage && typeof t === 'function') {
    const product = prepareForProductPage(language);
    return `${envConfig.APP.URL}/${language}/marketplace/${product}?q=${t(
      `category.${category}.name`
    )}`;
  }

  return `${envConfig.APP.URL}/${language}/marketplace/${categoryByRoute}/${normalizeCategoryWithLanguage}`;
}

export function mountMarketplaceCategoryPage(language: Language) {
  const categoryByRoute = categoryRoute[language];
  return `${envConfig.APP.URL}/${language}/marketplace/${categoryByRoute}`;
}

export function generateCampaignByLanguage(language: Language, slug: string) {
  if (isBlackNovember() && isBlackNovemberRoute(slug)) {
    return `${envConfig.APP.URL}/${language}/marketplace/${slug}`;
  }

  language = language.toString().toLowerCase() as Language;
  const campaignByRoute = campaignPageRoute[language];

  return `${envConfig.APP.URL}/${language}/marketplace/${campaignByRoute}/${slug}`;
}

function isURLCampaign(url: string) {
  const regEx = /(utm_source|utm_medium|utm_content|utm_campaign|utm_term|utm_content)/gm;
  return regEx.test(url);
}

function extractHostURL(url: string) {
  let hostname = '';

  if (url) {
    try {
      const previousPath = new URL(url);
      hostname = previousPath?.hostname?.replace('www.', '') || hostname;
    } catch (e) {
      return hostname;
    }
  }

  return hostname;
}

export const isOrganicSearch = (previousPath: string | undefined) => {
  if (previousPath) {
    if (isURLCampaign(previousPath)) {
      return false;
    }

    const source = extractHostURL(previousPath);
    const searchEngines = [
      'google.com',
      'bing.com',
      'r.search.yahoo.com',
      'br.search.yahoo.com',
      'duckduckgo.com',
      'ecosia.org',
      'yandex.com'
    ];

    return searchEngines.includes(source);
  }

  return false;
};

export function generateBlackNovemberCampaignByLanguage(
  language: Language,
  slug: string
) {
  language = language?.toString()?.toLowerCase() as Language;
  return `${envConfig.APP.URL}/${language}/marketplace/${slug}`;
}
